<template>
    <div class="section_wpr mb-2">
        <div class="edit_section">
            <NotifyMethods @getData="getVal"/>
        </div>
    </div>
    <div v-if="notifyData === 'mail' || notifyData === 'both'">
        <label for="email_alert" class="switch_option capsule_btn m-0">
            <h5>Email Setup<span>Aliquam elementum tincidunt lorem gravida imperdiet. Ut lobortis magna velit.</span></h5>
            <input type="checkbox" id="email_alert" v-model="emailAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr mb-4" v-if="emailAlert">
            <EmailComp isRecipient/>
        </div>
    </div>
    <div v-if="notifyData === 'sms' || notifyData === 'both'">
        <label for="sms_alert" class="switch_option capsule_btn">
            <h5>SMS Setup<span>Aliquam elementum tincidunt lorem gravida imperdiet. Ut lobortis magna velit.</span></h5>
            <input type="checkbox" id="sms_alert" v-model="smsAlert" hidden>
            <div><span></span></div>
        </label>
        <div class="setting_wpr" v-if="smsAlert">
            <SmsComp isContact/>
        </div>
    </div>
</template>

<script>
import NotifyMethods from '@/components/NotifyMethods';
import EmailComp from '@/components/EmailComp';
import SmsComp from '@/components/SmsComp';
export default {
    name: 'NotificationSetting',
    components:{
        NotifyMethods,
        EmailComp,
        SmsComp
    },
    data(){
        return{
            notifyData: 'mail',
            emailAlert: true,
            smsAlert: true,
        }
    },
    methods:{
        getVal(val){
            this.notifyData = val;
        },
    }
}
</script>
